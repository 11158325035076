<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data'/>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('miracle_deal.striker_order') }}
              </h3>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-center px-8 px-lg-10">
                <div class="col-xl-12 col-xxl-7 justify-content-center">
                    <!--begin::Form Wizard-->
                    <ValidationObserver v-slot="{ handleSubmit }">
                    <form class="form">
                        <!--begin::Step 1-->
                        <div data-wizard-type="step-content" data-wizard-state="current">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('miracle_deal.user') }}</label>
                                        <div class="col-lg-9 col-xl-9">
                                          <validation-provider rules="required" :name="$t('miracle_deal.user')" v-slot="{ classes, errors }">
                                            <multiselect
                                                v-model="selectedUser"
                                                id="ajax"
                                                :options="userList"
                                                 :placeholder="$t('commons.choose')"
                                                :loading="searchIsLoading"
                                                :internal-search="false"
                                                open-direction="bottom"
                                                @search-change="getData"
                                                :class="classes"
                                            >
                                                <template slot='singleLabel' slot-scope='{ option }'>{{ option.title }}</template>
                                                <template slot='option' slot-scope='{ option }'>{{ option.title }}</template>
                                                <template slot='noOptions'>{{ $t('commons.noOptions') }}</template>
                                            </multiselect>
                                            <span class="form-text text-muted">{{ $t('miracle_deal.user_desc') }}</span>
                                            <span class="error__message">{{ errors[0] }}</span>
                                          </validation-provider>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('miracle_deal.duration') }}</label>
                                        <div class="col-lg-9 col-xl-9">
                                          <div class="row">
                                            <div class="col-lg-6 col-sm-12">
                                              <validation-provider rules="required|min_value:0" :name="$t('miracle_deal.duration')" v-slot="{ classes, errors }">
                                                <input
                                                  class="form-control"
                                                  :class="classes"
                                                  type="number"
                                                  v-model="keeperOrder.duration"
                                                  :min="selectedDurationType.min"
                                                  :max="selectedDurationType.max"
                                                  :placeholder="selectedDurationType.placeHolder"
                                                />
                                                <span class="form-text text-muted">{{ $t('miracle_deal.duration_desc') }}</span>                                            
                                                <span class="error__message">{{ errors[0] }}</span>
                                              </validation-provider>
                                            </div>
                                            <div class="col-lg-6 col-sm-12">
                                                <multiselect
                                                  v-model="selectedDurationType"
                                                  :options="signDurationTypes"
                                                  :internal-search="true"
                                                  :allow-empty="false"
                                                  open-direction="bottom"
                                                  deselect-label=''
                                                  select-label=''
                                                  selected-label=''
                                                  track-by='name'
                                                  label='name'
                                                  placeholder='Select Type'
                                                >
                                                    <template slot='singleLabel' slot-scope='{ option }'>{{ option.name }}</template>
                                                    <template slot='option' slot-scope='{ option }'>{{ option.name }}</template>
                                                    <template slot='noOptions'>{{ 'List is empty' }}</template>
                                                </multiselect>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('miracle_deal.sign_duration') }}</label>
                                        <div class="col-lg-9 col-xl-9">
                                          <div class="row">
                                            <div class="col-lg-6 col-sm-12">
                                              <validation-provider rules="required|min_value:0" :name="$t('miracle_deal.sign_duration')" v-slot="{ classes, errors }">
                                                <input
                                                  class="form-control"
                                                  :class="classes"
                                                  type="number"
                                                  v-model="keeperOrder.sign_duration"
                                                  :max="selectedSignDurationType.max"
                                                  :min="selectedSignDurationType.min"
                                                  :placeholder="selectedSignDurationType.placeHolder"
                                                />
                                                <span class="form-text text-muted">{{ $t('miracle_deal.sign_duration_desc') }}</span>
                                                <span class="error__message">{{ errors[0] }}</span>
                                              </validation-provider>
                                            </div>
                                            <div class="col-lg-6 col-sm-12">
                                                <multiselect
                                                  v-model="selectedSignDurationType"
                                                  :options="signDurationTypes"
                                                  :internal-search="true"
                                                  :allow-empty="false"
                                                  open-direction="bottom"
                                                  deselect-label=''
                                                  select-label=''
                                                  selected-label=''
                                                  track-by='name'
                                                  label='name'
                                                  placeholder='Select Type'
                                                >
                                                    <template slot='singleLabel' slot-scope='{ option }'>{{ option.name }}</template>
                                                    <template slot='option' slot-scope='{ option }'>{{ option.name }}</template>
                                                    <template slot='noOptions'>{{ 'List is empty' }}</template>
                                                </multiselect>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('miracle_deal.currency') }}</label>
                                        <div class="col-lg-9 col-xl-9">
                                            <validation-provider rules="required" :name="$t('commons.currency')" v-slot="{ classes, errors }">
                                            <multiselect
                                                v-model="selectedCurrency"
                                                :options="currencies"
                                                :internal-search="true"
                                                :loading="currenciesIsLoading"
                                                @input="onChangeCurrency"
                                                open-direction="bottom"
                                                deselect-label=''
                                                select-label=''
                                                selected-label=''
                                                track-by='name'
                                                label='name'
                                                :placeholder="$t('miracle_deal.choosen_currency')"
                                                :class="classes"
                                            >
                                              <template slot='singleLabel' slot-scope='{ option }'>{{ option.name }}</template>
                                              <template slot='option' slot-scope='{ option }'>{{ option.name }}</template>
                                              <template slot='noOptions'>{{ 'List is empty' }}</template>
                                            </multiselect>
                                            <span class="form-text text-muted">{{ $t('miracle_deal.currency_desc') }}</span>
                                            <span class="error__message">{{ errors[0] }}</span>
                                          </validation-provider>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('miracle_deal.quantity') }}</label>
                                        <div class="col-lg-9 col-xl-9">
                                          <validation-provider rules="required" :name="$t('miracle_deal.quantity')" v-slot="{ classes, errors }">
                                            <currency-input
                                              class="form-control"
                                              :placeholder="$t('miracle_deal.quantity')"
                                              :class="classes"
                                              :precision="currencyPrecision"
                                              v-model="keeperOrder.quantity"
                                            />
                                            
                                            <span class="form-text text-muted">{{ $t('miracle_deal.quantity_desc') }}</span>
                                            <span class="error__message">{{ errors[0] }}</span>
                                          </validation-provider>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('miracle_deal.ratio') }}</label>
                                        <div class="col-lg-9 col-xl-9">
                                          <validation-provider rules="required|min_value:0" :name="$t('miracle_deal.ratio')" v-slot="{ classes, errors }">
                                            <input
                                              type="number"
                                              min="0.01"
                                              max="100"
                                              step="0.01"
                                              pattern="^\d*(\.\d{0,2})?$"
                                              class="form-control"
                                              :class="classes"
                                              v-model="keeperOrder.ratio"
                                              :placeholder="$t('miracle_deal.ratio_desc')"
                                            />
                                            <span class="form-text text-muted">{{ $t('miracle_deal.ratio_desc') }}</span>                                        
                                            <span class="error__message">{{ errors[0] }}</span>
                                          </validation-provider>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--begin::Actions-->
                        <div class="d-flex justify-content-end border-top mt-5 pt-10">
                            <form-submit-btn :isSubmitting="isSubmitting" @click="handleSubmit(createKeeper)" />
                        </div>
                        <!--end::Actions-->
                    </form>
                    </ValidationObserver>
                    <!--end::Form Wizard-->
                </div>
            </div>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import i18n from '@/config/_i18n'

const signDurations = [
  { value: "2", name: i18n.t("commons.day"), min: 1, max: 31, placeHolder: "1 - 31" },
  { value: "3", name: i18n.t("commons.month"), min: 1, max: 12, placeHolder: "1 - 12" },
  { value: "4", name: i18n.t("commons.year"), min: 1, max: 100, placeHolder: "1 - 100" },
];

export default {
  name: 'StrikerOrder',
  data() {
    return {
      keeperOrder: {
        duration: '',
        durationType: '',
        sign_duration: '',
        sign_duration_type: '',
        quantity: null,
        ratio: ''
      },
      selectedCurrency: null,
      selectedUser: '',
      currencyPrecision: 2,
      signDurationTypes: signDurations,
      selectedDurationType: signDurations[0],
      selectedSignDurationType: signDurations[0],
      isSubmitting: false
    }
  },
  components: {
    Breadcrumb,
    Multiselect,
  },
  computed: {
    ...mapState({
      currenciesIsLoading: state => state.currencies.isLoading,
      searchIsLoading: state => state.global.searchUserIsLoading,
      userList: state => state.global.userList,
      currencies: state => state.currencies.currencies,
    }),
    breadcrumb_data() {
      return {
        title:  this.$t('miracle_deal.title'),
        subtitle: [
          this.$t('miracle_deal.striker_order')
        ]
      }
    }

  },
  methods: {
    ...mapActions('keeper', ['POST_KEEPER']),
    ...mapActions('currencies', ['GET_CURRENCIES']),
    ...mapActions('global', ['SEARCH_USER_KEEPER']),
    createKeeper() {
      let data = { ...this.keeperOrder, 
        user_id: this.selectedUser?.id || '', 
        currency_id: this.selectedCurrency?.id || '',
        durationType: this.selectedDurationType?.value || '',
        sign_duration_type: this.selectedSignDurationType?.value || ''
      };
      // console.log(data)
      this.isSubmitting = true;
      this.POST_KEEPER(data)
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.isSubmitting = false;
        })
    },
    getData(query){
        console.log(query)
        console.log("esc:",escape(query))
        this.SEARCH_USER_KEEPER(query)
    },
    onChangeCurrency() {
      this.currencyPrecision = this.selectedCurrency?.decimal || 8;
    }
  },
  created() {
    this.getData();
    this.GET_CURRENCIES().then(() => {
      if(this.currencies.length > 0) {
        this.selectedCurrency = this.currencies[0]
        this.onChangeCurrency()
      }
    })
  }
}
</script>
